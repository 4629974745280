<template>
  <div class="home">
    <div class="home-banner">
      <img src="../assets/logo.png" class="logo" />
    </div>
    <div class="container">
      <div class="section section-safety">
        <div class="section-title">
          <!-- <img src="../assets/title-2.png" /> -->
        </div>
        <div class="section-content">
          <div class="guide-box-top">
            <div class="guide-box-left">
              <!-- 轮播图 -->
              <Banner :list="safeData.banners" @click="bannerClick($event, 2)">
              </Banner>
              <!-- 资讯动态 -->
              <router-link class="news-section" :to="{ path: '/safetylist' }">
                <span>资讯动态</span>
                <i class="iconfont icon-youjiantou"></i>
              </router-link>
              <div class="news-list">
                <div
                  class="news-card"
                  v-for="item in safeData.news"
                  :key="item.identify"
                  @click="toNewsDetail(item)"
                >
                  <div class="news-image">
                    <img :src="item.cover" />
                  </div>
                  <span class="news-title">{{ item.title }}</span>
                </div>
              </div>
            </div>
            <div class="guide-box-right">
              <a data-bs-toggle="modal" data-bs-target="#ModalScan"
                ><img src="../assets/safety/home-answer.png"
              /></a>
              <router-link :to="{ path: '/videolist' }"
                ><img src="../assets/safety/home-video.png"
              /></router-link>
              <router-link :to="{ path: '/missionlist' }"
                ><img src="../assets/safety/home-siji.png"
              /></router-link>
              <!-- <a href="/videolist"><img src="../assets/safety/home-video.png" /></a> -->
              <!-- <a href="/missionlist"><img src="../assets/safety/home-siji.png" /></a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="section section-company">
        <div class="section-title">
          <!-- <img src="../assets/title-1.png" /> -->
        </div>
        <div class="section-content">
          <div class="guide-box-top">
            <div class="guide-box-left">
              <!-- 轮播图 -->
              <div
                id="carouselExampleCaptions"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    v-for="(item, index) in companyData.banners"
                    :key="item.paper_id"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    :data-bs-slide-to="index"
                    :class="
                      companyData.curBanners.paper_id == item.paper_id
                        ? 'active'
                        : ''
                    "
                    aria-current="true"
                    :aria-label="item.paper_id"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div
                    @click="bannerClick(item, 1)"
                    :class="[
                      'carousel-item',
                      companyData.curBanners.paper_id == item.paper_id
                        ? 'active'
                        : '',
                    ]"
                    v-for="item in companyData.banners"
                    :key="item.paper_id"
                  >
                    <img
                      src="../assets/company/home-slide.png"
                      class="d-block w-100"
                    />
                    <div class="carousel-caption d-none d-md-block">
                      <h5>{{ item.title }}</h5>
                    </div>
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>

              <div class="guide-box-left-top">
                <router-link :to="{ path: '/companylist', query: { type: 2 } }">
                  <img src="../assets/company/home-province.png" />
                </router-link>
                <router-link :to="{ path: '/companylist', query: { type: 1 } }">
                  <img src="../assets/company/home-country.png" />
                </router-link>
              </div>
              <div class="guide-box-left-top">
                <router-link
                  :to="{ path: '/companylist', query: { type: 2, tid: 2 } }"
                >
                  <img src="../assets/company/home-society.png" />
                </router-link>
                <router-link :to="{ path: '/expertlist' }">
                  <img src="../assets/company/home-expert.png" />
                </router-link>
              </div>
            </div>
            <div class="guide-box-right">
              <router-link
                :to="{
                  path: '/companynotice',
                  query: { code: 'notification' },
                }"
              >
                <img src="../assets/company/home-notice.png" />
              </router-link>
              <router-link
                :to="{ path: '/companynotice', query: { code: 'rules' } }"
              >
                <img src="../assets/company/home-policy.png" />
              </router-link>
              <div class="company-box">
                <a
                  class="btn-company btn-company-guide"
                  type="button"
                  href="https://hbemergency.hbzhengwu.com/companyarticle/notification/107"
                ></a>
                <a
                  class="btn-company btn-company-signup"
                  type="button"
                  @click="showRegisterModal"
                ></a>
                <a class="btn-company" @click="toCompanyApply"></a>
                <!-- <router-link class="btn-company"
                             :to="{ path: '/companyers' }"></router-link> -->
                <img src="../assets/company/home-apply.png" />
              </div>
              <router-link :to="{ path: '/eqlist' }">
                <img src="../assets/company/home-eq.png" />
              </router-link>
            </div>
          </div>
          <div class="guide-box-bottom">
            <div class="guide-box-left"></div>
            <div class="guide-box-right"></div>
          </div>
          <div class="detail-title title-blue">企业风采</div>
          <div class="news-list">
            <div
              class="news-card"
              v-for="item in companyData.news"
              :key="item.paper_id"
              @click="toCompanyNewsDetail(item)"
            >
              <div class="news-image">
                <img :src="item.cover" />
              </div>
              <span class="news-title">{{ item.title }}</span>
            </div>
          </div>
          <router-link class="company-more" :to="{ path: '/companynews' }">
            查看更多 <i class="iconfont icon-youjiantou"></i
          ></router-link>
        </div>
      </div>
      <div class="section section-vol">
        <div class="section-title">
          <!-- <img src="../assets/title-3.png" /> -->
        </div>
        <div class="section-content">
          <div class="guide-box-top">
            <div class="guide-box-left">
              <!-- 轮播图 -->
              <Banner
                :list="volunteerData.banners"
                @click="toVolNewsDetail($event, 3)"
              >
              </Banner>
            </div>
            <div class="guide-box-right">
              <router-link
                class="item"
                :to="{ name: 'EventList', params: { type: 3 } }"
              >
                <img src="../assets/volunteer/home-icon-event.png" />
                找活动
              </router-link>
              <router-link class="item" :to="{ name: 'OrgList' }">
                <img src="../assets/volunteer/home-icon-org.png" />
                找组织
              </router-link>
              <router-link class="item" :to="{ name: 'volRequire' }">
                <img src="../assets/volunteer/home-icon-require.png" />
                发需求
              </router-link>
              <!-- <router-link class="item" :to="{ name: 'volRank' }">
                <img src="../assets/volunteer/home-icon-rank.png" />
                爱心榜
              </router-link> -->
            </div>
          </div>

          <div class="guide-box-bottom">
            <router-link :to="{ name: 'VolPersonLogin' }">
              <img src="../assets/volunteer/home-person.png" />
            </router-link>
            <router-link :to="{ name: 'VolOrgLogin' }">
              <img src="../assets/volunteer/home-org.png" />
            </router-link>
            <!-- <a href="/volorglogin"></a> -->
          </div>
          <!-- 志愿资讯 -->
          <router-link
            class="news-section vol-news-section"
            :to="{ path: '/volnews' }"
          >
            <span>志愿资讯</span>
            <i class="iconfont icon-youjiantou"></i>
          </router-link>
          <div class="news-list">
            <div
              class="news-card"
              v-for="item in volunteerData.news"
              :key="item.identify"
              @click="toVolNewsDetail(item)"
            >
              <div class="news-image">
                <img :src="item.cover" />
              </div>
              <span class="news-title">{{ item.title }}</span>
            </div>
          </div>
          <ul class="nav nav-tabs nav-red" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                :class="[
                  'nav-link',
                  volunteerData.curEventType == 1 ? 'active' : '',
                ]"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
                @click="changeEventType(1)"
              >
                省级专项活动
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                :class="[
                  'nav-link',
                  volunteerData.curEventType == 2 ? 'active' : '',
                ]"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected="false"
                @click="changeEventType(2)"
              >
                地市精彩活动
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              role="tabpanel"
              aria-labelledby="home-tab"
              :tabindex="volunteerData.curEventType - 1"
            >
              <div class="none-data" v-if="volunteerData.eventList.length == 0">
                <img src="../assets/none.png" class="img-none" />
              </div>
              <div class="vol-news-list">
                <div
                  class="vol-news-item"
                  v-for="item in volunteerData.eventList"
                  :key="item.event_id"
                  @click="toEventDetail(item)"
                >
                  <div class="vol-news-pic">
                    <img :src="item.image" />
                  </div>
                  <span class="vol-news-title">{{ item.title }}</span>
                  <span class="vol-news-time">{{ item.created_at }}</span>
                </div>
              </div>
              <div
                class="vol-more"
                @click="toEventList"
                v-if="volunteerData.eventList.length > 0"
              >
                查看更多 <i class="iconfont icon-youjiantou"></i>
              </div>
            </div>
            <!-- <div class="tab-pane fade"
                 id="profile-tab-pane"
                 role="tabpanel"
                 aria-labelledby="profile-tab"
                 tabindex="0">
              ...
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade modal-wait"
      id="ModalWait"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center">敬请期待</div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade modal-wait"
      id="ModalScan"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center">
            <img src="../assets/gh.jpg" /><br />
            打开手机微信扫一扫<br />
            参加应急安全知识网络竞赛
          </div>
        </div>
      </div>
    </div>
    <!-- Modal 注册-->
    <div
      class="modal fade modal-company-signup"
      id="modal-company-signup"
      tabindex="-1"
      v-if="userInfo != null"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <b>企业注册</b>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p v-if="userInfo.is_company == '3'">
              您的企业注册信息未通过审核，请重新修改提交。
            </p>
            <p v-if="userInfo.is_company == '1'">您的企业注册信息正在审核。</p>
            <form @submit.prevent="toRegisterCompany" v-if="!hasReg">
              <div class="mb-3">
                <label class="form-label">企业所属地市</label>
                <div class="form-area">
                  <select
                    class="form-select"
                    v-if="selCity"
                    v-model="cityId"
                    :disabled="canEditCompanyRegister"
                    @change="cityChanged"
                    name="city"
                  >
                    <option
                      v-for="item in cityList"
                      :key="item.area_id"
                      :value="item.area_id"
                    >
                      {{ item.area_name }}
                    </option>
                  </select>
                  <select
                    class="form-select"
                    v-if="selDistrict"
                    v-model="districtId"
                    :disabled="canEditCompanyRegister"
                    @change="districtChanged"
                    name="district"
                  >
                    <option
                      v-for="item in districtList"
                      :key="item.area_id"
                      :value="item.area_id"
                    >
                      {{ item.area_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">企业全称</label>
                <input
                  type="text"
                  :disabled="canEditCompanyRegister"
                  class="form-control"
                  v-model="company_name"
                  placeholder="请务必输入正确的企业全称（同营业执照）"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">您的职务</label>
                <input
                  type="text"
                  :disabled="canEditCompanyRegister"
                  class="form-control"
                  v-model="manager_position"
                  placeholder="请输入您的职务"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">您的真实姓名</label>
                <input
                  type="text"
                  class="form-control"
                  :value="userInfo.real_name"
                  disabled
                />
              </div>
              <div class="mb-3">
                <label class="form-label">您的手机号码</label>
                <input
                  type="text"
                  class="form-control"
                  :value="userInfo.mobile"
                  disabled
                />
              </div>
              <div class="d-grid">
                <button
                  type="submit"
                  :disabled="userInfo.is_company == '1'"
                  class="btn btn-primary w-full"
                >
                  提交
                </button>
              </div>
            </form>
            <!-- 审核中 -->
            <!-- <div class="text-center"
                 v-if="hasReg">
              <p>
                您的企业注册信息已提交成功，请等待管理员审核。<br />审核通过后，方可进行在线申报。
                <br />
                您可以先了解相关流程，准备相关材料。
              </p>
              <router-link :to="{ name: 'CompanyERS' }" >
              <button @click="closeRegModal"
                      class="btn btn-primary w-full">
                了解相关流程
              </button>
            </router-link>

            </div>-->
            <!-- 通过 -->
            <!-- <div class="text-center"
                 v-if="hasReg">
              <p>
                您的企业注册信息已通过审核。
              </p>
              <router-link :to="{ name: 'CompanyERS' }" >
              <button @click="closeRegModal"
                      class="btn btn-primary w-full">
                请进行企业申报
              </button>
            </router-link>
            </div>  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  login,
  fetchBanner,
  articleList,
  paperList,
  volunteerTopList,
  eventList,
  articleDetail,
  paperDetail,
  companyRegister,
  userDetail,
  areaList,
} from "@/network/API.js";
import Banner from "@/components/Banner.vue";
export default {
  components: {
    Banner,
  },
  data() {
    return {
      regModal: {},
      company_name: "",
      manager_position: "",
      hasReg: false,
      userInfo: this.$store.getters.userInfo,
      canEditCompanyRegister: true,
      companyData: {
        banners: [],
        curBanners: 0,
        news: [],
      },
      safeData: {
        banners: [],
        curBanners: 0,
        news: [],
      },
      volunteerData: {
        banners: [],
        curBanners: 0,
        curEventType: 1,
        eventList: [],
        news: [],
      },
      cityList: [],
      districtList: [],
      selCity: {},
      selDistrict: {},
      cityId: "",
      districtId: "",
    };
  },
  async created() {
    if (this.$route.query.code) {
      let code = this.$route.query.code;
      await login({
        code,
      }).then((res) => {
        this.$store.dispatch("updateUser", res);
        this.$store.dispatch("updateToken", res.token);
        this.userInfo = this.$store.getters.userInfo;
        this.$router.replace({
          path: "/",
        });
        // if (res.complete_code && res.complete_code.length > 0) {
        //   this.completeCode = res.complete_code;
        //   this.$refs.btn.click();
        // } else {
        //   this.$store.dispatch("updateUser", res);
        //   this.$store.dispatch("updateToken", res.token);
        // }
      });
    }
    this.getBanner(1);
    this.getBanner(2);
    this.getVolunteerTop();
    this.getSafeNews();
    this.getEventList();
    this.getVolunteerNews();
    this.getCompanyNews();
    if (this.$store.getters.hasLogin) {
      let res = await userDetail();
      this.userInfo = res;
      this.$store.dispatch("updateUser", res);
      this.company_name = res.company_name;
      this.manager_position = res.manager_position;
      if (this.userInfo.is_company == "3" || this.userInfo.is_company == "0") {
        this.canEditCompanyRegister = false;
      } else {
        this.canEditCompanyRegister = true;
      }
      this.cityId = this.userInfo.company_city_code;
      this.districtId = this.userInfo.company_district_code;
      this.selCity = {
        area_id: this.userInfo.company_city_code,
        area_name: this.userInfo.company_city,
      };
      this.selDistrict = {
        area_id: this.userInfo.company_district_code,
        area_name: this.userInfo.company_district,
        parent_id: this.userInfo.company_city_code,
      };
    }
    this.getAreaList();
    if (this.userInfo && this.userInfo.company_city_code != "") {
      this.getAreaList(this.selCity.area_id);
    }
  },
  methods: {
    toCompanyApply() {
      let token = window.localStorage.getItem("token");
      if (!token || token == "") {
        this.$toast("请先登录");
        return;
      }
      if (this.userInfo.is_company == 0) {
        this.$toast("请先完成企业注册。");
        return;
      }
      if (this.userInfo.is_company == 1) {
        this.$toast("您的企业注册信息正在审核，请等待。");
        return;
      }
      if (this.userInfo.is_company == 3) {
        this.$toast("您的企业注册信息审核未通过，请重新注册。");
        return;
      }
      this.$router.push("/companyers");
    },
    cityChanged(e) {
      this.selCity = this.cityList.filter((item) => {
        return item.area_id == e.target.value;
      })[0];
      this.cityId = e.target.value;
      if (
        this.selDistrict &&
        this.selDistrict.parent_id != this.selCity.area_id
      ) {
        this.selDistrict = {};
        this.districtId = "";
      }
      this.getAreaList(this.selCity.area_id);
    },
    districtChanged(e) {
      this.districtId = e.target.value;
      this.selDistrict = this.districtList.filter((item) => {
        return item.area_id == e.target.value;
      })[0];
    },
    async getAreaList(cityId) {
      let list = await areaList({
        code: cityId ? cityId : "130000",
      });
      if (cityId) {
        this.districtList = list;
      } else {
        this.cityList = list;
      }
    },
    closeRegModal() {
      this.hasReg = false;
      this.regModal.hide();
    },
    showRegisterModal() {
      if (!this.userInfo) {
        this.$toast("请先登录");
        return;
      }
      if (this.userInfo.is_company == 2) {
        this.$toast("企业已注册");
        return;
      }
      this.regModal = new bootstrap.Modal(
        document.getElementById("modal-company-signup"),
        {}
      );
      this.regModal.show();
    },
    async toRegisterCompany() {
      if (this.selCity.area_id == "" || this.selCity.area_id == undefined) {
        this.$toast("请选择企业所属地市");
      }
      if (
        this.selDistrict.area_id == "" ||
        this.selDistrict.area_id == undefined
      ) {
        this.$toast("请选择企业所属地市");
      }
      if (this.company_name == "") {
        this.$toast("请输入企业全称");
        return;
      }
      if (this.manager_position == "") {
        this.$toast("请输入您的职务");
        return;
      }
      await companyRegister({
        company_name: this.company_name,
        manager_position: this.manager_position,
        city: this.selCity.area_name,
        city_code: this.selCity.area_id,
        district: this.selDistrict.area_name,
        district_code: this.selDistrict.area_id,
      });
      this.$toast("已提交，等待审核");
      this.hasReg = true;
      this.userInfo = await userDetail();
      this.$store.dispatch("updateUser", userInfo);
    },
    async getVolunteerNews() {
      let data = await paperList({
        code: "volunteer",
        page_size: 3,
        page: 1,
      });
      this.volunteerData.news = data.list;
    },
    async getCompanyNews() {
      let data = await paperList({
        code: "fengcai",
        page_size: 3,
        page: 1,
      });
      this.companyData.news = data.list;
    },
    async getBanner(type) {
      let data = await fetchBanner({
        type_id: type,
      });
      if (type == 2) {
        let res = await paperList({
          code: "notification",
          is_top: 1,
        });
        this.companyData.banners = res.list;
        if (res.list.length > 0) {
          this.companyData.curBanners = res.list[0];
        }
      }
      if (type == 1) {
        this.safeData.banners = data;
      }
    },
    async getSafeNews() {
      let data = await articleList({
        page_size: 2,
        page: 1,
        code: "news",
      });
      this.safeData.news = data;
    },
    async getVolunteerTop() {
      let data = await paperList({
        is_top: 1,
        code: "volunteer",
      });
      this.volunteerData.banners = data.list;
    },
    async getEventList() {
      let data = await eventList({
        is_top: 1,
        page_size: 3,
        region_type: this.volunteerData.curEventType,
      });
      this.volunteerData.eventList = data.list;
    },
    async bannerClick(event, type) {
      if (type == 1) {
        //安全文化建设
        console.log(event.paper_id);
        this.$router.push({
          name: "CompanyArticle",
          params: {
            code: "notification",
            id: event.paper_id,
          },
        });
      } else if (type == 2) {
        //应急管理宣传
        if (event.feed_id && event.finder_user_name) {
          //视频号
          this.$toast("暂不支持查看视频号");
          return;
        }
        let detail = await articleDetail(event.identify);
        if (detail.offiaccount_url) {
          // window.location.href = detail.offiaccount_url;
          window.open(detail.offiaccount_url);
          return;
        }
        if (event.source_url) {
          // window.location.href = detail.source_url
          window.open(detail.offiaccount_url);
          return;
        }
        if (event.identify) {
          this.$router.push({
            name: "SafetyDetail",
            params: {
              id: event.identify,
            },
          });
          return;
        }
      } else {
        //志愿者
      }
    },
    async toVolNewsDetail(item) {
      //志愿资讯
      if (item.feed_id && item.finder_user_name) {
        //视频号
        this.$toast("暂不支持查看视频号");
        return;
      }
      let detail = await paperDetail(item.paper_id);
      if (detail.offiaccount_url) {
        window.location.href = detail.offiaccount_url;
        // window.open(detail.offiaccount_url)
        return;
      }
      if (item.source_url) {
        window.location.href = detail.source_url;
        return;
      }
      if (item.paper_id) {
        this.$router.push({
          name: "VolunteerNewsDetail",
          params: {
            id: detail.paper_id,
          },
        });
        return;
      }
    },
    async toCompanyNewsDetail(item) {
      //企业风采
      if (item.feed_id && item.finder_user_name) {
        //视频号
        this.$toast("暂不支持查看视频号");
        return;
      }
      let detail = await paperDetail(item.paper_id);
      if (detail.offiaccount_url) {
        window.location.href = detail.offiaccount_url;
        // window.open(detail.offiaccount_url)
        return;
      }
      if (item.source_url) {
        window.location.href = detail.source_url;
        return;
      }
      if (item.paper_id) {
        this.$router.push({
          name: "CompanyNewsDetail",
          params: {
            id: detail.paper_id,
          },
        });
        return;
      }
    },
    async toNewsDetail(item) {
      //应急管理宣传
      if (item.feed_id && item.finder_user_name) {
        //视频号
        this.$toast("暂不支持查看视频号");
        return;
      }
      let detail = await articleDetail(item.identify);
      if (detail.offiaccount_url) {
        window.location.href = detail.offiaccount_url;
        // window.open(detail.offiaccount_url)
        return;
      }
      if (item.source_url) {
        window.location.href = detail.source_url;
        return;
      } else {
        this.$router.push({
          name: "SafetyDetail",
          params: {
            id: detail.identify,
          },
        });
        return;
      }
    },
    changeEventType(type) {
      this.volunteerData.curEventType = type;
      this.getEventList();
    },
    toEventDetail(item) {
      console.log(item.event_id);
      this.$router.push({
        name: "EventDetail",
        params: {
          id: item.event_id,
          type: this.volunteerData.curEventType,
        },
      });
    },
    toEventList() {
      this.$router.push({
        name: "EventList",
        params: {
          type: this.volunteerData.curEventType,
        },
      });
    },
  },
};
</script>
<style>
/* 
 */
.section-company .img-content {
  background: url(../assets/company/home-slide.png) no-repeat;
}

.section-company .img-content img {
  visibility: hidden;
}

.swiper-box {
  width: 750px;
  height: 390px;
}

.carousel-inner {
  width: 750px;
  border-radius: 10px;
}

.home-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 260px;
  background: url(../assets/home-banner.png) bottom center no-repeat;
}

.logo {
  transform: scale(0.8);
}

.section {
  margin: 0 -12px;
}

.section-title {
  padding: 20px 0;
}

.guide-box-top,
.guide-box-bottom {
  display: flex;
  justify-content: space-between;
}

.guide-box-top a,
.guide-box-bottom a {
  margin: 15px 0;
  display: inline-flex;
  cursor: pointer;
  transition: all linear 0.2s;
}

.guide-box-top a:hover img,
.guide-box-bottom a:hover img {
  transform: translateY(-5px);
  transition: all linear 0.2s;
}

.guide-box-top .guide-box-left {
  display: flex;
  flex-direction: column;
}

.guide-box-top .guide-box-left .guide-box-left-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.guide-box-top .carousel {
  margin: 15px 0;
  cursor: pointer;
}

.guide-box-top .guide-box-right {
  width: 360px;
}

.guide-box-bottom .guide-box-right {
  width: 750px;
}

.guide-box-bottom .guide-box-left {
  width: 360px;
}

.guide-box-top a.mr {
  margin-right: 30px;
}

/* .carousel-indicators{
  background: #3AA2E1;
} */
/* 志愿者 */
.section-vol .guide-box-top .guide-box-right {
  background: linear-gradient(
    180deg,
    rgba(255, 175, 80, 0.3) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px;
  height: 390px;
  margin: 15px 0;
}

.section-vol .guide-box-top .guide-box-right .item {
  width: 120px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  text-decoration: none;
  font-size: 21px;
}

.section-vol .guide-box-top .guide-box-right .item img {
  margin-bottom: 10px;
}

/* 资讯动态 */

.news-section {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  background: #fff;
  transition: all linear 0.2s;
  text-decoration: none;
}

.news-section:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

.news-section span {
  color: #005dab;
  font-size: 30px;
  font-weight: 600;
}

.news-section i {
  font-size: 30px;
  color: #005dab;
}

.news-list {
  display: flex;
  margin: 0 -15px;
}

.news-card {
  flex: 0 0 360px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 15px 15px;
  width: 360px;
  transition: all linear 0.2s;
}

.news-card:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

.news-image {
  width: 360px;
  height: 220px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.news-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-title {
  font-size: 21px;

  max-lines: 2;
  margin: 20px 0;
  color: #333333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-wait .modal-header {
  border-bottom: none;
}

.modal-wait .modal-body {
  min-height: 100px;
}

.vol-news-section {
  margin-top: 30px;
}

.vol-news-section span,
.vol-news-section i {
  color: #ff3241;
}

.section-vol .nav-tabs .nav-link {
  font-size: 28px;
  font-weight: 700;
}

.company-box {
  position: relative;
  margin: 15px 0;
}

.company-box .btn-company {
  position: absolute;
  width: 280px;
  height: 50px;
  background: transparent;
  left: 35px;
  top: 236px;
  z-index: 9;
}

.company-box .btn-company-signup {
  top: 174px;
}
.company-box .btn-company-guide {
  top: 112px;
}
.form-area {
  display: flex;
  justify-content: space-around;
}
.form-area select {
  margin-right: 10px;
}
</style>
